import {
  Column,
  DataTableSkeleton,
  Button,
  TableContainer,
} from '@carbon/react';
import './ParameterTable.scss';
import { useTranslation } from 'react-i18next';
import { ArrowRight } from '@carbon/icons-react';

const ParameterTableSkeleton = (props: { isLoading: boolean }) => {
  const { t } = useTranslation();
  const { isLoading } = props;

  if (isLoading) {
    return (
      <>
        <Column lg={14} md={6} sm={4} className="status-table-left">
            <DataTableSkeleton
              columnCount={7} // Match the number of headers in your full component
              rowCount={1}
              headers={[
                t('common.parameterTable.itemNumber'),
                t('common.parameterTable.supplierItemNumber'),
                t('common.parameterTable.CNNumber'),
                t('common.parameterTable.CNName'),
                t('common.parameterTable.dueDate'),
                t('common.parameterTable.importerName'),
                t('common.parameterTable.status'),
              ]}
              compact={false}
              zebra={false}
              showHeader={false}
              showToolbar={false}
            />
        </Column>
        <Column lg={2} md={2} sm={4} className="status-table-right">
          <Button disabled={true}
          className="status-submit-btn"
          tooltipAlignment="start"
          renderIcon={ArrowRight}
          >
            {t('common.buttons.loading')}
          </Button>
        </Column>
      </>
    );
  }

  // Here would be the actual content render when not loading
  // This return can be your actual ParameterTable component or its simplified version.
  return <div>Your actual content here</div>;
};

export default ParameterTableSkeleton;
